import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice';
import { GetApi,MultipartApi } from '../../../Common/ApiAxios';
import MediaDataStore from './MediaDataStore'
import MediaHeaderStore from './MediaHeaderStore'
import Input from '../../../Common/Atoms/Input'
import { useAlert } from 'react-alert'

const DefaultUrl = '/api/masterManagement/location'

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogStyle = styled.div`
  width: 1000px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 18px;
  text-align: left;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 16px;
`;

const Label = styled.label`
  width: 120px;
  text-align: left;
`;

const InputField = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectField = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 30px;
`;

const Button = styled.button`
  width: 90px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #649cdf;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

  &.clear-btn {
    background-color: #979797;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
  &.clear-btn:hover {
    background-color: #858585;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
`;
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
const SearchStoreDialog = ({ data,onClose,onReSerach }) => {
  const [storeMasterList, setStoreMasterList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const alert = useAlert();
  const [filters, setFilters] = useState(
    {
      storeCode:'',
      storeName:''
    }
  );

  const dispatch = useDispatch();
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  // Fetch data functions
  const getStoreMaster = async (isLoadSpinner) => {
    if (isLoadSpinner) dispatch(SetLoadSpinner(true));

    const apiUrl = `${DefaultUrl}/store`;

    const result = await GetApi(apiUrl);
    if (isLoadSpinner) dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('該当データがありません（店舗マスタの取得）');
      setStoreMasterList([]);
      setFilteredData([]); // データがない場合もリセット
    } else {
      setStoreMasterList(result.data);
      setFilteredData(result.data); // 初期状態では全データを表示
    }
  };
  // `Search` コンポーネントから渡される検索条件を基にデータをフィルタリング
  const handleSearch = (filters) => {
    const safeFilters = filters || {};
  
    if (Object.values(safeFilters).every(value => !value)) {
      // 全条件が空の場合、全データを表示
      setFilteredData(storeMasterList);
      return;
    }
  
    const filtered = storeMasterList.filter(item => {
      return (
        (!filters.storeCode || item.storeCode?.toString().includes(filters.storeCode)) &&
        (!filters.storeName || item.storeName?.toString().includes(filters.storeName))
        );
      });
  
      setFilteredData(filtered);
    };
    const handleInputChange = (e) => {
      const { id, value } = e.target;
      setFilters((prev) => ({ ...prev, [id]: value }));
    };
  useEffect(() => {
    getStoreMaster(true);
  }, []);
  useEffect(() => {
    handleSearch(filters);
  }, [filters,storeMasterList]);

  // `Update` ロケーションの登録処理
  // 
  const handleUpdate =  async(store) => {
      var param =
      {
        baseId: data.baseId,
        ownerCd: data.ownerCd,
        locationNum: data.locationNum,
        shippingCd: store.companyCode+store.storeCode,
        shippingName: store.storeName,
        shippingKana: store.kana,
        companyCode: store.companyCode,
        storeCode: store.storeCode,
        areaId: "-1"
      }
      dispatch(SetLoadSpinner(true));
      const apiUrl = `${DefaultUrl}/update`;
      console.log(param)
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(param)], { type: 'application/json' }))
      const result = await MultipartApi(apiUrl,'PUT',submitData,param);
      dispatch(SetLoadSpinner(false));
      if(result.errorDetail)
      {
        alert.error('ロケーションの登録に失敗しました。');
      }else{
        alert.info('ロケーションの登録に成功しました。');
        onReSerach(true);
        onClose();
      }
  };

  return (
    <DialogOverlay onClick={handleOverlayClick}>
      <DialogStyle>
      <Title><h2>店舗登録</h2></Title>
      <FormRow>
      <label style={{width:'7rem',marginLeft:'12px'}}>店舗コード</label>
      <Input width={'10rem'} 
        type="text"
        id="storeCode"
        value={filters.storeCode}
        onChange={handleInputChange}
      />
      <label style={{width:'7rem',marginLeft:'12px'}}>店舗名</label>
      <Input width={'10rem'} 
        type="text"
        id="storeName"
        value={filters.storeName}
        onChange={handleInputChange}
      />
      </FormRow>
      <div style={{width:'817px', marginLeft:'20px', marginRight:'20px', textAlign:'left'}}>
        <MediaHeaderStore />
        <ListUl width={filteredData.length >= 13 ? '817px' : '800px'} height={'543px'}>
        {filteredData.map((item,index) => (
          <ListLi key={index} style={{cursor: 'default'}}>
          <MediaDataStore data={item} handleUpdate={handleUpdate}/>
          </ListLi>
        ))}
        </ListUl>
        </div>
        <ButtonContainer>
          <Button onClick={onClose}>閉じる</Button>
        </ButtonContainer>
      </DialogStyle>
    </DialogOverlay>
  );
};

SearchStoreDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SearchStoreDialog;
