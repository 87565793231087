import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogStyle = styled.div`
  width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 18px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 16px;
`;

const Label = styled.label`
  width: 120px;
  text-align: left;
`;

const InputField = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectField = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 30px;
`;

const Button = styled.button`
　width: 90px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #649cdf;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

  &.clear-btn {
    background-color: #979797;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
  &.clear-btn:hover {
    background-color: #858585;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
`;

const SearchDialog = ({ onClose, onSearch, initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters);

  const ownerOptions = ['尾西', 'MV静岡', 'MV東海'];
  const patternOptions = ['登録有', '登録無'];

  const handleSearch = () => {
    onSearch(filters);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: value }));
  };

  const handleClearAll = () => {
    const clearedFilters = {
      companyCode: '',
      storeCode: '',
      storeName: '',
      nohinCenterName: '',
      ownerName: '',
      locationCd: '',
      patternCd: '',
    };
    setFilters(clearedFilters);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <DialogOverlay onClick={handleOverlayClick}>
      <DialogStyle>
        <Title><h2>絞り込み条件</h2></Title>
        <div>
          <FormRow>
            <Label htmlFor="companyCode">会社コード</Label>
            <InputField
              type="text"
              id="companyCode"
              value={filters.companyCode}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="storeCode">店舗コード</Label>
            <InputField
              type="text"
              id="storeCode"
              value={filters.storeCode}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="storeName">店舗名</Label>
            <InputField
              type="text"
              id="storeName"
              value={filters.storeName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="nohinCenterName">納品センター名</Label>
            <InputField
              type="text"
              id="nohinCenterName"
              value={filters.nohinCenterName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="ownerName">荷主</Label>
            <SelectField
              id="ownerName"
              value={filters.ownerName}
              onChange={handleInputChange}
            >
              <option value="">-</option>
              {ownerOptions.map((owner, index) => (
                <option key={index} value={owner}>{owner}</option>
              ))}
            </SelectField>
          </FormRow>
          <FormRow>
            <Label htmlFor="locationCd">ロケーション</Label>
            <InputField
              type="text"
              id="locationCd"
              value={filters.locationCd}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="patternCd">パターン</Label>
            <SelectField
              id="patternCd"
              value={filters.patternCd}
              onChange={handleInputChange}
            >
              <option value="">-</option>
              {patternOptions.map((pattern, index) => (
                <option key={index} value={pattern}>{pattern}</option>
              ))}
            </SelectField>
          </FormRow>
        </div>
        <ButtonContainer>
          <Button className="clear-btn" onClick={handleClearAll}>クリア</Button>
          <Button onClick={handleSearch}>検索</Button>
        </ButtonContainer>
      </DialogStyle>
    </DialogOverlay>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.object.isRequired,
};

export default SearchDialog;
