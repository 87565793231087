import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
const DefaultUrl = '/api/afsTruckDashboardManagement/dashboard'

const ContentWrap = styled.form`
  display: block; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  text-align: center;
`

const Main = (props) => {
  const dispatch = useDispatch();
  const [pageUrl,setPageUrl]=useState();
  const { loginUserInfo } = useSelector(state => state);
  // メインダッシュボードのURLを取得
  const apiGetIframeURL = async (isLoadSplinner) => {
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getMainUrl';
    
    const data = {
      staffId:loginUserInfo.staffId
    }
    console.log(data)
    const result = await GetApi(apiUrl, data);
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
    } else {
      console.log(result);
      setPageUrl(result.data)
    }
  }
  // 描画時に一度だけ実行
  useEffect(() => {
    if(loginUserInfo.staffId){
     
      apiGetIframeURL(true)
    }
  }, [loginUserInfo])

  return (
    <ContentWrap>
      {!!pageUrl &&
        <iframe 
          width="1250px" 
          height="840px" 
          src={pageUrl}
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
          allowFullScreen 
        >
        </iframe>
      }
    </ContentWrap>
  )
}

export default Main
