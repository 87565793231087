import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import EditIcon from '../../../../image/edit-icon.png'
import SearchStoreDialog from './SearchStoreDialog'
import SearchStoreDialogEdit from './SearchStoreDialogEdit'
import { MultipartApi } from '../../../Common/ApiAxios';
import { useDispatch } from 'react-redux'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import { useAlert } from 'react-alert'
import Input from '../../../Common/Atoms/Input'

const DefaultUrl = '/api/masterManagement/location'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const MediaLastItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;
`
const ScreenButton = styled.button`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;
  vertical-align: middle;
  color: ${props => props.activeState ? '#fff' : '#000'};
  background-color: ${props => props.activeState ? '#649CDF' : '#ccc'};
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
`
const FunctionIcon = styled.img`
  width: 18px;
  height: 18px;
`

const SelectField = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const MediaDataLocation= (props) => {
  const {
    data,onReSerach,handleChange,areaList,isEdit,setIsEdit
  } = props
  //const [isEdit,setIsEdit]= useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [editData, setEditData] = useState(
    {
      shippingCd: data.shippingCd,
      shippingName: data.shippingName,
      shippingKana: data.shippingKana,
      areaId: data.areaId
    }
  );
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEditData((prev) => ({ ...prev, [id]: value }));
  };

  // 初期値
  useEffect(() => {
    setEditData(
      {
        shippingCd: data.shippingCd,
        shippingName: data.shippingName,
        shippingKana: data.shippingKana,
        areaId: data.areaId
      }
    )
  },[data])
  // `Clear` ロケーションの解除処理
  const handleClear =  async() => {
    var param =
    {
      baseId: data.baseId,
      ownerCd: data.ownerCd,
      locationNum: data.locationNum,
      shippingCd: '',
      shippingName: '',
      shippingKana: '',
      companyCode: '',
      storeCode: '',
      areaId: "-1"
    }
    dispatch(SetLoadSpinner(true));
    const apiUrl = `${DefaultUrl}/clear`;
    console.log(param)
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(param)], { type: 'application/json' }))
    const result = await MultipartApi(apiUrl,'PUT',submitData,param);
    dispatch(SetLoadSpinner(false));
    if(result.errorDetail)
    {
      alert.error('ロケーションの解除に失敗しました。');
    }else{
      alert.info('ロケーションの解除に成功しました。');
      onReSerach(true);
    }
  };
  // `Update` ロケーションの更新処理
  const handleUpdate =  async() => {
      var param =
      {
        baseId: data.baseId,
        ownerCd: data.ownerCd,
        locationNum: data.locationNum,
        shippingCd: editData.shippingCd,
        shippingName: editData.shippingName,
        shippingKana: editData.shippingKana,
        companyCode: '',
        storeCode: '',
        areaId: editData.areaId
      }
      dispatch(SetLoadSpinner(true));
      const apiUrl = `${DefaultUrl}/update`;
      console.log(param)
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(param)], { type: 'application/json' }))
      const result = await MultipartApi(apiUrl,'PUT',submitData,param);
      dispatch(SetLoadSpinner(false));
      if(result.errorDetail)
      {
        alert.error('ロケーションの更新に失敗しました。');
      }else{
        alert.info('ロケーションの更新に成功しました。');
        onReSerach(true);
        setIsEdit(false);
      }
  };
  const onInsertLocaton = (e) => {
    setIsDialogOpen(true)
  }
  const onEditLocaton = (e) => {
    let element = document.getElementsByName(`shippingCd_${data.ownerCd}_${data.locationNum}`);
    console.log(element[0].value);
    setIsEditDialogOpen(true)
  }
  const SetEditLocaton = (editData) => {
    console.log(editData)
    let elementCd = document.getElementsByName(`shippingCd_${data.ownerCd}_${data.locationNum}`);
    elementCd[0].value = editData.shippingCd;
    let elementName = document.getElementsByName(`shippingName_${data.ownerCd}_${data.locationNum}`);
    elementName[0].value = editData.shippingName;
    let elementKana = document.getElementsByName(`shippingKana_${data.ownerCd}_${data.locationNum}`);
    elementKana[0].value = editData.shippingKana;
    let elementArea = document.getElementsByName(`areaId_${data.ownerCd}_${data.locationNum}`);
    elementArea[0].value = editData.areaId;
    setEditData(editData)
  }
  const handleEditChangeLocaton = (locationNum,ownerCd,type) => {
    let elementCd1 = document.getElementsByName(`shippingCd_${data.ownerCd}_${data.locationNum}`);
    let elementName1 = document.getElementsByName(`shippingName_${data.ownerCd}_${data.locationNum}`);
    let elementKana1 = document.getElementsByName(`shippingKana_${data.ownerCd}_${data.locationNum}`);
    let elementArea1 = document.getElementsByName(`areaId_${data.ownerCd}_${data.locationNum}`);
    var tmpEditData =
    {
      shippingCd:  elementCd1[0].value,
      shippingName: elementName1[0].value,
      shippingKana: elementKana1[0].value,
      areaId:  elementArea1[0].value
    }
    var editData =
    {
      shippingCd:  elementCd1[0].value,
      shippingName: elementName1[0].value,
      shippingKana: elementKana1[0].value,
      areaId:  elementArea1[0].value
    }
    let element = document.getElementsByName(`shippingCd_${data.ownerCd}_${data.locationNum+type}`);
    if(element[0].disabled || elementCd1[0].disabled){
      alert.error('対象のロケーションは現在、パターンに割り振られているので更新できません。');
      return
    }
    let elementCd = document.getElementsByName(`shippingCd_${data.ownerCd}_${data.locationNum+type}`);
    tmpEditData.shippingCd = JSON.parse(JSON.stringify(elementCd[0].value))
    elementCd[0].value = editData.shippingCd;
    let elementName = document.getElementsByName(`shippingName_${data.ownerCd}_${data.locationNum+type}`);
    tmpEditData.shippingName = JSON.parse(JSON.stringify(elementName[0].value))
    elementName[0].value = editData.shippingName;
    let elementKana = document.getElementsByName(`shippingKana_${data.ownerCd}_${data.locationNum+type}`);
    tmpEditData.shippingKana = JSON.parse(JSON.stringify(elementKana[0].value))
    elementKana[0].value = editData.shippingKana;
    let elementArea = document.getElementsByName(`areaId_${data.ownerCd}_${data.locationNum+type}`);
    tmpEditData.areaId = JSON.parse(JSON.stringify(elementArea[0].value))
    elementArea[0].value = Number(editData.areaId);
    SetEditLocaton(tmpEditData)
  }
  const getAreaName = (areaId) => {
    var areaName = ''
    try{
      areaName = areaList.find((area)=> area.areaId == areaId).areaName
      if(areaId == -1){
        areaName = ''
      }
    }catch{
    }
    return areaName
  }
  if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.ownerName}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'right'}}>
          <div style={{marginRight:'2rem'}}>{data.locationNum}</div>
        </TextWrap>
      </MediaItem>
      {!isEdit &&
      <>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.shippingCd}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'300px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.shippingName}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'300px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.shippingKana}</div>
        </TextWrap>
      </MediaItem>
      </>
      }
      {isEdit &&
      <>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'left'}}>
          <Input 
            width={'140px'} 
            type="text"
            id="shippingCd"
            name={`shippingCd_${data.ownerCd}_${data.locationNum}`}
            disabled={!!data.patternFlag}
            defaultValue={data.shippingCd}
            onChange={handleInputChange}
          />
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'300px', textAlign:'left'}}>
          <Input 
            width={'290px'} 
            type="text"
            id="shippingName"
            name={`shippingName_${data.ownerCd}_${data.locationNum}`}
            disabled={!!data.patternFlag}
            defaultValue={data.shippingName}
            onChange={handleInputChange}
          />
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'300px', textAlign:'left'}}>
          <Input 
            width={'290px'} 
            type="text"
            id="shippingKana"
            name={`shippingKana_${data.ownerCd}_${data.locationNum}`}
            disabled={!!data.patternFlag}
            defaultValue={data.shippingKana}
            onChange={handleInputChange}
          />
        </TextWrap>
      </MediaItem>
      </>
      }
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div style={{marginLeft:'0rem'}}>{data.companyCode}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div style={{marginLeft:'0rem'}}>{data.storeCode}</div>
        </TextWrap>
      </MediaItem>
      {!isEdit &&
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div style={{marginRight:'0rem'}}>{getAreaName(data.areaId)}</div>
        </TextWrap>
      </MediaItem>
      }
      {isEdit &&
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
            <SelectField
              id="areaId"
              name={`areaId_${data.ownerCd}_${data.locationNum}`}
              value={editData.areaId}
              onChange={handleInputChange}
              disabled={!!data.patternFlag}
            >
              {areaList.map(item => (
                <option key={'area_'+item.areaId} value={item.areaId}>{item.areaId==-1?'未選択':item.areaName}</option>
              ))}
            </SelectField>
        </TextWrap>
      </MediaItem>
      }
      {!isEdit &&
      <MediaLastItem>
        {/* <div style={{margin:'auto 0 auto 0.5rem'}}>
          <ScreenButton 
            name='登録' padding='0px' type="button"  
            onClick={onInsertLocaton} 
            activeState={!data.patternFlag} 
            disabled={!!data.patternFlag}
          >
          <TextWrap style={{textAlign:'center'}}>
            <div>登録</div>
          </TextWrap>
          </ScreenButton>
        </div>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          <ScreenButton 
            name='解除' padding='0px' type="button" 
            onClick={handleClear} 
            activeState={!data.patternFlag && data.shippingCd} 
            disabled={!!data.patternFlag || !data.shippingCd}
          >
          <TextWrap style={{textAlign:'center'}}>
            <div>解除</div>
          </TextWrap>
          </ScreenButton>
        </div>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          {data.locationNum != 300 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}} onClick={()=>handleChange(data.locationNum,data.ownerCd,1)}>
            <div>▼</div>
          </TextWrap>
          }
          {data.locationNum == 300 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}}>
            <div>&nbsp;&nbsp;&nbsp;</div>
          </TextWrap>
          }
        </div>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          {data.locationNum != 1 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}} onClick={()=>handleChange(data.locationNum,data.ownerCd,-1)}>
            <div>▲</div>
          </TextWrap>
          }
          {data.locationNum == 1 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}}>
            <div>&nbsp;&nbsp;&nbsp;</div>
          </TextWrap>
          }
        </div> */}
        {/* <div style={{margin:'auto 0 auto 0.5rem'}}>
          <TextWrap style={{textAlign:'center' ,cursor:'pointer'}}>
            <FunctionIcon  
              alt='編集' 
              src={EditIcon} 
              disabled={!!data.patternFlag}
              onClick={()=>{setIsEdit(!data.patternFlag)}} 
            />
          </TextWrap>
        </div> */}
      </MediaLastItem>
      }
      {isEdit &&
      <MediaLastItem>
        {/* <div style={{margin:'auto 0 auto 0.5rem'}}>
          <ScreenButton 
            name='キャンセル' padding='0px' type="button"  
            onClick={()=>setIsEdit(false)} 
            activeState={true} 
          >
          <TextWrap style={{textAlign:'center'}}>
            <div>キャンセル</div>
          </TextWrap>
          </ScreenButton>
        </div>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          <ScreenButton 
            name='更新' padding='0px' type="button" 
            activeState={!data.patternFlag} 
            disabled={!!data.patternFlag}
            onClick={handleUpdate}
          >
          <TextWrap style={{textAlign:'center'}}>
            <div>更新</div>
          </TextWrap>
          </ScreenButton>
        </div> */}
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          <ScreenButton 
            name='選択' padding='0px' type="button"  
            onClick={onEditLocaton} 
            activeState={!data.patternFlag} 
            disabled={!!data.patternFlag}
          >
          <TextWrap style={{textAlign:'center'}}>
            <div>店舗選択</div>
          </TextWrap>
          </ScreenButton>
        </div>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          <div style={{display:'flex',flexDirection: 'row'}}>
          <input 
            name={`clear_${data.ownerCd}_${data.locationNum}`} padding='0px' type="checkbox" 
            activeState={!data.patternFlag && data.shippingCd} 
            disabled={!!data.patternFlag || !data.shippingCd}
          />
          <TextWrap style={{textAlign:'center'}}>
            <div>解除</div>
          </TextWrap>
          </div>
        </div>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          {data.locationNum != 300 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}} onClick={()=>handleEditChangeLocaton(data.locationNum,data.ownerCd,1)}>
            <div>▼</div>
          </TextWrap>
          }
          {data.locationNum == 300 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}}>
            <div>&nbsp;&nbsp;&nbsp;</div>
          </TextWrap>
          }
        </div>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          {data.locationNum != 1 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}} onClick={()=>handleEditChangeLocaton(data.locationNum,data.ownerCd,-1)}>
            <div>▲</div>
          </TextWrap>
          }
          {data.locationNum == 1 &&
          <TextWrap style={{textAlign:'center',cursor:'pointer'}}>
            <div>&nbsp;&nbsp;&nbsp;</div>
          </TextWrap>
          }
        </div>
      </MediaLastItem>
      }
      {isDialogOpen && <SearchStoreDialog onClose={()=>setIsDialogOpen(false)} data={data} onReSerach={onReSerach}/>}
      {isEditDialogOpen && <SearchStoreDialogEdit onClose={()=>setIsEditDialogOpen(false)} data={data} setEditData={SetEditLocaton}/>}
    </MediaWrap>
  )
}

MediaDataLocation.propTypes = {
  data: PropTypes.object, // ダイアログ
}

export default MediaDataLocation
