import React, { useEffect,useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1798px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const MediaTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const MediaHeaderBlock = (props) => {
  const {
    locationList
  } = props
  const [locationItem, setLocationItem] = useState();

  // 初期値
  useEffect(() => {
    if(locationList && locationList.length){
      setLocationItem(locationList[0])
      console.log(locationList[0])
    }
  },[locationList])

  return (
    <>
      <MediaWrap>
      <MediaItem>
        <MediaTitle style={{width:'85px'}}>ブロック名</MediaTitle>
      </MediaItem>
      {locationItem?.patternLocationInfo.map((item,index)=>(
      <MediaItem key={index+'_blockName'}>
        <TextWrap style={{width:'424px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{item.blockName}</div>
        </TextWrap>
      </MediaItem>
      ))}
    </MediaWrap>
    <MediaWrap>
      <MediaItem>
        <MediaTitle style={{width:'85px'}}>ブロックカナ</MediaTitle>
      </MediaItem>
      {locationItem?.patternLocationInfo.map((item,index)=>(
      <MediaItem key={index+'_blockKana'}>
        <TextWrap style={{width:'424px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{item.blockKana}</div>
        </TextWrap>
      </MediaItem>
      ))}
    </MediaWrap>
    </>
  )
}

MediaHeaderBlock.propTypes = {
  locationList: PropTypes.array, // データリスト
}

export default MediaHeaderBlock
