import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import EditIcon from '../../../../image/edit-icon.png'
import SearchStoreDialog from './SearchStoreDialog'
const MediaWrap = styled.div`
  width:800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const MediaLastItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;
`
const ScreenButton = styled.button`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;
  vertical-align: middle;
  color: ${props => props.activeState ? '#fff' : '#000'};
  background-color: ${props => props.activeState ? '#649CDF' : '#ccc'};
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
`
const FunctionIcon = styled.img`
  width: 18px;
  height: 18px;
`
const MediaDataStore= (props) => {
  const {
    grants,
    handleUpdate,
    data,
  } = props


  if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div style={{marginLeft:'0rem'}}>{data.companyCode}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div style={{marginLeft:'0rem'}}>{data.storeCode}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'300px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.storeName}</div>
        </TextWrap>
      </MediaItem>
      <MediaLastItem>
        <div style={{margin:'auto 0 auto 0.5rem'}}>
          <ScreenButton 
            name='登録' padding='0px' type="button"  
            onClick={()=>handleUpdate(data)} 
            activeState={!data.patternFlag} 
            disabled={!!data.patternFlag}
          >
          <TextWrap style={{textAlign:'center'}}>
            <div>登録</div>
          </TextWrap>
          </ScreenButton>
        </div>
      </MediaLastItem>
    </MediaWrap>
  )
}

MediaDataStore.propTypes = {
  data: PropTypes.object, // VAS個人実績
  handleUpdate: PropTypes.func
}

export default MediaDataStore
