import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogStyle = styled.div`
  width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 18px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 16px;
`;

const Label = styled.label`
  width: 85px;
  text-align: left;
`;

const InputField = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectField = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 30px;
`;

const Button = styled.button`
  width: 90px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #649cdf;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

  &.clear-btn {
    background-color: #979797;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
  &.clear-btn:hover {
    background-color: #858585;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
`;

const SearchDialog = ({ onClose, onSearch, initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters);

  const pcrsDivisionOptions = ['PC', 'RS'];

  const handleSearch = () => {
    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== '')
    );
    onSearch(validFilters);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: value }));
  };  

  const handleClearAll = () => {
    const clearedFilters = {
      itemNo: '',
      itemName: '',
      itemKana: '',
      pcrsDivision: '',
      janCode: '',
    };
    setFilters(clearedFilters); // フィルタをリセット
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <DialogOverlay onClick={handleOverlayClick}>
      <DialogStyle>
        <Title><h2>絞り込み条件</h2></Title>
        <div>
          <FormRow>
            <Label htmlFor="itemNo">品番</Label>
            <InputField
              type="text"
              id="itemNo"
              value={filters.itemNo}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="itemName">商品名</Label>
            <InputField
              type="text"
              id="itemName"
              value={filters.itemName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="itemKana">カナ</Label>
            <InputField
              type="text"
              id="itemKana"
              value={filters.itemKana}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="pcrsDivision">PCRS</Label>
            <SelectField
              id="pcrsDivision"
              value={filters.pcrsDivision}
              onChange={handleInputChange}
            >
              <option value="">-</option>
              {pcrsDivisionOptions.map((pcrs, index) => (
                <option key={index} value={pcrs}>{pcrs}</option>
              ))}
            </SelectField>
          </FormRow>
          <FormRow>
            <Label htmlFor="janCode">JANコード</Label>
            <InputField
              type="text"
              id="janCode"
              value={filters.janCode}
              onChange={handleInputChange}
            />
          </FormRow>
        </div>
        <ButtonContainer>
          <Button className="clear-btn" onClick={handleClearAll}>クリア</Button>
          <Button onClick={handleSearch}>検索</Button>
        </ButtonContainer>
      </DialogStyle>
    </DialogOverlay>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.object.isRequired,
};

export default SearchDialog;
