import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

import MediaHeaderDaily from './Atoms/MediaHeaderLocation'
import MediaDataLocation from './Atoms/MediaDataLocation'
import MediaHeaderBlock from './Atoms/MediaHeaderBlock'

import DownloadIcon from '../../../image/down-load-csv-icon.png'
import Tooltip from '@material-ui/core/Tooltip'
import {onExportCsvFile} from '../../Common/Utilties/ExportUtility'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { MultipartApi } from '../../Common/ApiAxios';
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'

import EditIcon from '../../../image/edit-icon.png'
import UpdateIcon from '../../../image/update-icon.png'

const DefaultUrl = '/api/masterManagement/patternLocation'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
  height: 520px;
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
  padding:0.5rem;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
const FunctionIcon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
const LocationTableBoard = (props) => {
  const {
    locationList,
    onReSerach,
    handleChange,
    storeList,
    locationOrgList
  } = props
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isEdit,setIsEdit]= useState(false);
  const onExport=()=>{
    let exportResult = makeCSV()
    let fileName = LogisticUtility.getStringFromDate(new Date(), 'YYYYMMDD') + "AFS尾西センター_パターンロケーション一覧";
    onExportCsvFile(exportResult,fileName);
  }
  function isDuplicated(elements) {
    // Setを使って、配列の要素を一意にする
    const setElements = new Set(elements);
    return setElements.size !== elements.length;
  }  
  function isLocationExist(elements) {
    const l = elements.filter(element=>{
      return storeList.indexOf(Number(element.locationNum))>-1
    })
    return !!!l.length;
  }

  function makeCSV() 
  {
    let Header = ''
    let header =[
      'No',
      '荷主コード',
      '荷主名',
      'パターンコード',
      'ブロック名',
      'ロケーション番号',
      '出荷先コード',
      '出荷先名',
      '出荷先カナ'
      ]
    header.map((head)=>{
      //console.log(head)
      Header+=head
      Header+=','
    })
    let result = Header + '\r\n'
    var tmpList=JSON.parse(JSON.stringify(locationList))
    //console.log(locationList)
    var resultList = []
    tmpList.map((tmp)=> {
      //console.log(tmp)
      return tmp.patternLocationInfo.map((value,index)=>{

        var lineResult =
        value.sort + ',' +
        value.ownerCd + ',' +
        value.ownerName + ',' +
        value.patternCd + ',' +
        value.blockName + ',' +
        value.locationNum + ',' +
        value.shippingCd + ',' +
        value.shippingName + ',' +
        value.shippingKana + ',' 
  
        lineResult = lineResult.replaceAll('null','')

        if(!resultList[index]){
          resultList[index]=''
        }
        resultList[index] += lineResult
        
        resultList[index]  += '\r\n'
      })
      return 
    })
    console.log(resultList)
    resultList.map((value)=>{
      result+=value
    })
    return result
  }
  // `Update` ロケーションの登録処理
  const handleUpdate =  async() => {
    console.log(locationList)
    //更新リスト
    var paramList = []
    //削除リスト
    var paramDelList = []
    //重複確認リスト
    var paramCheckList = []
    locationList.map((tmp)=> {
      return tmp.patternLocationInfo.map((data,index)=>{
        var param =
        {
          baseId: data.baseId,
          ownerCd: data.ownerCd,
          locationNum: data.editLocationNum,
          patternCd:  data.patternCd,
          sort: data.sort,
          blockName: data.blockName,
          cooperationFlag: data.cooperationFlag
        }
        var paramDel =
        {
          baseId: data.baseId,
          ownerCd: data.ownerCd,
          locationNum: data.locationNum,
          patternCd:  data.patternCd,
          sort: data.sort,
          blockName: data.blockName,
          cooperationFlag: data.cooperationFlag
        }
        if(data.locationNum != data.editLocationNum){
          console.log(data)
          if(data.editLocationNum){
            paramList.push(param)
          }
          if(data.locationNum){
            paramDelList.push(paramDel)
          } 
        }
      }) 
    })
    //重複のチェック用
    locationOrgList.map((item)=>{ 
      item.patternLocationInfo.map((info)=>{
        var paramCheck = info.locationNum
        var updateParam = paramList.find((param)=>param.sort == info.sort && param.blockName == info.blockName)
        var delParam = paramDelList.find((param)=>param.sort == info.sort && param.blockName == info.blockName)
        if(delParam)paramCheck = ''
        if(updateParam)paramCheck = updateParam.locationNum
        if(paramCheck != null && paramCheck != '') paramCheckList.push(Number(paramCheck))
        return
      })
      return
    })
    console.log(paramList)
    console.log(paramDelList)
    console.log(paramCheckList)
    //重複チェック
    if(isDuplicated(paramCheckList)){
      var duplicationList = paramCheckList.filter(function (param, index, self) {
        return self.indexOf(param) === index && index !== self.lastIndexOf(param);
      });
      console.log(duplicationList)
      alert.error('更新するロケーションが重複しています。');
      return
    }

    //店舗チェック
    if(isLocationExist(paramList)){
      alert.error('更新するロケーションに店舗が割り振られていません。');
      return
    }

    //ロケーションが登録されているときは先に解除
    if(!paramDelList.length && !paramList.length){
      alert.error('更新するパターンロケーションがありません。');
      return
    }
   
    {
      const param =
      {
        paramList: paramList,
        paramDelList: paramDelList
      }
      dispatch(SetLoadSpinner(true));
      const apiUrl = `${DefaultUrl}/edit`;
      console.log(param)
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(param)], { type: 'application/json' }))
      const result = await MultipartApi(apiUrl,'PUT',submitData,param);
      dispatch(SetLoadSpinner(false));
      if(result.errorDetail)
      {
        alert.error('パターンロケーションの編集に失敗しました。');
      }else{
        alert.info('パターンロケーションの編集に成功しました。');
        onReSerach();
        setIsEdit(false);
      }
    }
  };
  // 検索条件変更時に実行
  useEffect(() => {
    setIsEdit(false);
  }, [locationList])

  return (
    <ContentWrap>
      <BodyContent>
        <div style={{width:'1817px', marginLeft:'20px', marginRight:'20px', textAlign:'left'}}>
        <div style={{textAlign:'left',flexDirection:'row',marginRight:'17px',marginLeft:'10px'}}>
          <Tooltip title={'編集'}>
            <FunctionIcon  alt='編集' src={EditIcon}  style={{marginRight:'1rem'}} onClick={()=>setIsEdit(!isEdit)}/>
          </Tooltip>
          {isEdit &&
          <Tooltip title={'更新'}>
            <FunctionIcon  alt='更新' src={UpdateIcon}  style={{marginRight:'1rem'}} onClick={()=>handleUpdate()}/>
          </Tooltip>
          }
          <div style={{textAlign:'right',flexDirection:'row',float:'right'}}>
            <Tooltip title={'csvダウンロード'}>
              <FunctionIcon  alt='ダウンロード' src={DownloadIcon}  style={{marginRight:'1rem'}} onClick={onExport}/>
            </Tooltip>
            {locationList?.length.toLocaleString()}件
          </div>
        </div>
        <MediaHeaderBlock locationList={locationList}/>
        <MediaHeaderDaily />
        <ListUl width={locationList?.length >= 15 ? '1817px' : '1800px'} height={'563px'}>
        {locationList?.map((item,index) => (
          <ListLi key={index} style={{cursor: 'default'}}>
          <MediaDataLocation data={item} onReSerach={onReSerach} handleChange={handleChange} isEdit={isEdit}/>
          </ListLi>
        ))}
        </ListUl>
        </div>
      </BodyContent>
    </ContentWrap>
  )
}

LocationTableBoard.propTypes = {
}

export default LocationTableBoard
