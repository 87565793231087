import React,{useState, useEffect, useRef} from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../../Common/FunctionEnum'
import GRANT from '../../Common/GrantEnum'

import Select from '../../Common/Atoms/Select'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

import IconCollapseImg from '../../../image/Collapse.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconInquiryImg from '../../../image/inquiry-icon.png'
import styled from 'styled-components';
import SearchDialog from './Atoms/SearchDialog';

const SearchButtonStyle = styled.div`
  width: 140px;
  margin-left: 20px;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) => (props.isConditionSet ? '#ffc107' : '#002fff')}; /* 条件によって色を変更 */
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.4), inset 0 2px 0 0 rgba(255, 255, 255, 0.4);

  &:hover {
    background-color: ${(props) => (props.isConditionSet ? '#f0ba07' : '#0023e6')};
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.4), inset 0 2px 0 0 rgba(255, 255, 255, 0.4);
`;

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    searchDateRef,
    workplaceId,
    baseId,
    ownerList,
    onFilter
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const isCainz = GRANT.CAINZ === loginUserInfo.grantNameId;
  const isAFS = GRANT.AFS === loginUserInfo.grantNameId;
  const workplaceRef = useRef();
  const [isMasterOpen, setIsMasterOpen] = useState(false);
  const [workplaceList, setWorkplaceList] = useState([
      {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
    , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
    , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
    , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
    , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
  ]);

  const SearchButton = ({ onClick, isConditionSet }) => {
    return (
      <SearchButtonStyle onClick={onClick} isConditionSet={isConditionSet}>
        絞り込み検索
      </SearchButtonStyle>
    );
  };  
  // ダイアログの開閉状態とフィルター条件を管理
   // ダイアログの開閉状態とフィルター条件を管理
   const [isDialogOpen, setIsDialogOpen] = useState(false);
   const [filters, setFilters] = useState({
     ownerCd: '',
     locationNum: '',
     shippingName: '',
     companyCode: '',
     storeCode: '',
   });
  // 検索条件を設定し、ダイアログを閉じる
  const handleSearch = (newFilters) => {
    setFilters(newFilters); // 新しい検索条件を保存
    setIsDialogOpen(false); // ダイアログを閉じる
    console.log(newFilters)
    if (onFilter) {
      onFilter(newFilters); // 親に検索条件を通知
    }
  };

  // 検索条件が設定されているかを判定
  const isConditionSet = filters && Object.values(filters).some((value) => value.toString().trim() !== '');

  // ダイアログを開く
  const handleDialogOpen = () => setIsDialogOpen(true);

  // ダイアログを閉じる
  const handleDialogClose = () => setIsDialogOpen(false);

  const onChangeWorkplace = (e) => {
    //console.log(workplaceRef.current.value);

    let id = workplaceRef.current.value;
    //onWorkplaceChange(id);

    let workplace = workplaceList.find(element => element.id === id);
    if (workplace) {
      history.push(workplace.url);
    }
  }

  async function topPage () {
    history.push('/top');
  }
  const toggleMasterMenu = () => {
    setIsMasterOpen(!isMasterOpen);
  };
  async function vasPerformance () {
    var state = {searchDate:searchDateRef.current,workplaceId:workplaceId}
    var pathname = '/logistic/vasPerformance'
    workplaceList.map((value)=>{
      if(value.id == workplaceId) pathname = value.url
    })
    history.push({state:state,pathname:pathname});
    //history.push({state:state,pathname:'/logistic/vasPerformance'});
    //history.push('/logistic/vasPersonal');
  }
  async function vasSummary () {
    var state = {searchDate:searchDateRef.current,workplaceId:workplaceId,baseId:baseId}
    history.push({state:state,pathname:'/logistic/vasSummary'});
    //history.push('/logistic/vasPersonal');
  }
  async function vasRealTime () {
    var state = {searchDate:searchDateRef.current,workplaceId:workplaceId,baseId:baseId}
    history.push({state:state,pathname:'/logistic/vasRealTimeProgress'});
    //history.push('/logistic/vasRealTimeProgress');
  }
  async function vasPersonal () {
    var state = {searchDate:searchDateRef.current}
    history.push({state:state,pathname:'/logistic/vasPersonal'});
    //history.push('/logistic/vasPersonal');
  }
  async function vasMasterItem() {
    history.push({ pathname: '/logistic/vasMaster/item' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  
  async function vasMasterStore() {
    history.push({ pathname: '/logistic/vasMaster/store' });
    // history.push('/logistic/vasRealTimeProgress');
  }

  async function vasMasterLocation() {
    history.push({ pathname: '/logistic/vasMaster/location' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  async function vasPatternLocation() {
    history.push({ pathname: '/logistic/vasMaster/patternLocation' });
    // history.push('/logistic/vasRealTimeProgress');
  }
  useEffect(() => {
    if (isCainz) {
      setWorkplaceList([
        {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
      ]);
    } else if (isAFS) {
      setWorkplaceList(workplaceList.filter((elem) => { return elem.id == workplaceId}));
    } else {
      setWorkplaceList([
        {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
      , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
      , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
      , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
      , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
      ])
    }
  }, [isCainz, isAFS]);

  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'20px',margin:'6px 0 0 12px'}}>{'マスタ'}</div>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{'ロケ一覧'}</div>
      <ul className='mainSearch'>
        <li>
        <div style={{margin:'0 0px 0 0'}}>
        <Select selectRef={workplaceRef} onChange={onChangeWorkplace} width={'13rem'} disabled>
          {workplaceList.map(item => (
            <option key={'work_'+item.id} value={item.id} selected={item.id==workplaceId}>{item.name}</option>
          ))}
        </Select>
        </div>
        <div style={{ margin: '-5px 0 0px 0' }}>
        <SearchButton onClick={handleDialogOpen} isConditionSet={isConditionSet}/>
        </div>
        {isDialogOpen && (
          <SearchDialog 
            onClose={handleDialogClose} 
            onSearch={handleSearch}
            ownerList={ownerList}
            initialFilters={filters}
          />
        )}
        {/* <Tooltip title={'絞り込み検索'}>
          <img 
            src={IconSearchImg} 
            width="24px" height="24px" 
            style={{ marginTop: '4px', cursor: 'pointer' }} 
            onClick={()=>onClickOption(!viewSubSearch)} 
          />
        </Tooltip> */}
        </li>
        <li className='subSearch'>
          {/* <SubSearchContent>
            <SubSearchContentRow>
              <label style={{fontWeight:'bold',fontSize:'1rem'}}>絞り込み条件</label>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'7rem'}}>荷主</label>
              <Select selectRef={ownerRef} width={'22rem'}>
              {ownerList.map(item => (
                <option key={'owner_'+item.ownerCd} value={item.ownerCd}>{item.ownerName}</option>
              ))}
              </Select>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'7rem',marginLeft:'12px'}}>ロケーション番号</label>
              <Input width={'22rem'} inputRef={locationNumRef}/>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'7rem',marginLeft:'12px'}}>出荷先名</label>
              <Input width={'22rem'} inputRef={shippingRef}/>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'7rem',marginLeft:'12px'}}>会社コード</label>
              <Input width={'22rem'} inputRef={companyRef}/>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'7rem',marginLeft:'12px'}}>店舗コード</label>
              <Input width={'22rem'} inputRef={storeRef}/>
            </SubSearchContentRow>
            <SubSearchContentRow style={{textAlign:'right', marginLeft:'auto', marginRight:'1.5rem' }}>
              <Button type='button' margin='0 0 0 16px' name='' padding='0px' onClick={()=>onClickOption(false)}/>
              <Button type='button' margin='0 0 0 16px' name='検索' padding='0px' 
              onClick={()=>{
                onClickOption(false)
                onFilter()
              }} />
            </SubSearchContentRow>
          </SubSearchContent> */}
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
      <FunctionContent 
          onMouseLeave={() => {
            onCloseFunction();
            setIsMasterOpen(false);
          }}
        >
          {!isExternalUser &&
          <>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          </>
          }
          <FunctionContentRow onClick={vasSummary} style={{marginTop:'12px'}}>
            <FunctionIcon alt='VAS運用状況' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>VAS運用状況</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={vasRealTime} style={{marginTop:'12px'}}>
            <FunctionIcon alt='作業工程実績照会' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>作業工程実績照会</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={vasPerformance} style={{marginTop:'12px'}}>
            <FunctionIcon alt='種まきデータ実績照会' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>種まきデータ実績照会</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={vasPersonal} style={{marginTop:'12px'}}>
            <FunctionIcon alt='個人作業実績照会' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>個人作業実績照会</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={toggleMasterMenu} style={{ marginTop: '12px', marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
            <FunctionIcon alt='マスタ' src={IconInquiryImg} />
            <FunctionName><span style={{ marginLeft: '0.5rem' }}>マスタ</span></FunctionName>
            <span style={{ marginLeft: '12px' }}>{isMasterOpen ? '▲' : '▼'}</span>
          </FunctionContentRow>
          {isMasterOpen && (
            <>
            <FunctionContentRow style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="荷主マスタ" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>荷主マスタ</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasMasterItem} style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="製品マスタ" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>製品マスタ</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasMasterStore} style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="店舗マスタ" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>店舗マスタ</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasMasterLocation} style={{ marginTop: '12px', marginLeft: '1rem' }}>
              <FunctionIcon alt="ロケ一覧" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>ロケ一覧</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow  onClick={vasPatternLocation} style={{ marginTop: '12px', marginLeft: '1rem', marginBottom: '12px' }}>
              <FunctionIcon alt="パターンロケ一覧" src={IconInquiryImg} />
              <FunctionName><span style={{ marginLeft: '0.5rem' }}>パターンロケ一覧</span></FunctionName>
            </FunctionContentRow>
          </>
          )}
        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search
