import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SearchLocationDialog from './SearchLocationDialog'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;
`
const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const InputField = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80px;
`;

const MediaDataLocation= (props) => {
  const {
    data,onReSerach,handleChange,isEdit
  } = props
  //const [isEdit,setIsEdit]= useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDaialogData] = useState(data);
  const [editData, setEditData] = useState({});

  const handleInputChange = (e,index) => {
    const { id, value } = e.target;
    setEditData((prev) => ({ ...prev[index], [id]: value }));
    data.patternLocationInfo[index].editLocationNum = value  
  };  
  // 初期値
  useEffect(() => {
    var tmpEditData = []
    data.patternLocationInfo.map((item,index)=>{
      tmpEditData[`locationNum${index}`] = item.locationNum;
      item.editLocationNum  = item.locationNum;
    })
    setEditData(tmpEditData)
  },[data])

  const onInsertLocaton = (data) => {
    setDaialogData(data)
    setIsDialogOpen(true)
  }
  if (!data || !data?.patternLocationInfo) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'85px', textAlign:'right'}}>
          <div style={{marginRight:'2rem'}}>{data.sort}</div>
        </TextWrap>
      </MediaItem>
      {data?.patternLocationInfo.map((item,index)=>(
      <MediaRow key={data.sort+'_'+index}>
      {isEdit &&
      <>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'center'}}>
          <InputField
            type="text"
            id={`locationNum${index}`}
            value={editData[`locationNum${index}`]}
            onChange={(e)=>handleInputChange(e,index)}
          />
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'center'}}>
          <div style={{marginLeft:'0rem'}}>{item.shippingCd}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'220px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{item.shippingName}</div>
        </TextWrap>
      </MediaItem>
      </>
      }
      {!isEdit &&
      <>
      <MediaItem onClick={()=>onInsertLocaton(item)} style={{cursor:'pointer'}}>
        <TextWrap style={{width:'100px', textAlign:'right'}}>
          <div style={{marginRight:'2rem'}}>{item.locationNum}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'center'}}>
          <div style={{marginLeft:'0rem'}}>{item.shippingCd}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'220px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{item.shippingName}</div>
        </TextWrap>
      </MediaItem>
      </>
      }
      </MediaRow>
      ))}
      {isDialogOpen && <SearchLocationDialog onClose={()=>setIsDialogOpen(false)} data={dialogData} onReSerach={onReSerach}/>}
    </MediaWrap>
  )
}

MediaDataLocation.propTypes = {
  data: PropTypes.object, // ダイアログ
}

export default MediaDataLocation
