import React, { useState, useEffect,useRef,useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import LocationTableBoard from './LocationTableBoard'

import { MultipartApi } from '../../Common/ApiAxios';
import SideSubMenu from '../SideSubMenu'

const DefaultUrl = '/api/masterManagement/location'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const VASLocationMaster = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const [viewSubSearch,setViewSubSearch] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [locationList, setLocationList] = useState([]);
  const [locationOrgList, setLocationOrgList] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const searchWorkplaceRef = useRef();
  const history = useLocation(); 
  const hisState = history.state
  
  const ownerRef = useRef();
  const locationNumRef = useRef();
  const shippingRef = useRef();
  const companyRef = useRef();
  const storeRef = useRef();

  /*2023/01/29 K.Harada ADD 現場IDを表示するように変更 */
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000006'
  const baseId = history.state&&history.state.baseId?history.state.baseId:2
  //編集フラグ
  const [isEdit,setIsEdit]= useState(false);
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants]);

  const onFilter = useCallback((list,newFilters) => {
    return list.filter((info)=>{ 
      var ownerFg = !newFilters.ownerCd || newFilters.ownerCd == info.ownerCd.toString()
      var locationNumFg = !newFilters.locationNum || info.locationNum.toString().includes(newFilters.locationNum)
      var shippingName = info.shippingName?info.shippingName:'' 
      var shippingFg = !newFilters.shippingName || shippingName.toString().includes(newFilters.shippingName) 
      var companyCode = info.companyCode?info.companyCode:'' 
      var companyFg = !newFilters.companyCode || companyCode.toString().includes(newFilters.companyCode)  
      var storeCode = info.storeCode?info.storeCode:'' 
      var storeFg = !newFilters.storeCode || storeCode.toString().includes(newFilters.storeCode)  
      return ownerFg && locationNumFg && shippingFg && companyFg  && storeFg
    })
  }, [
    locationOrgList
  ])

  const onWorkplaceId=(id)=>{
    searchWorkplaceRef.current = id;

    getVasLocation(true);
  }
  
  // ロケ一覧の取得
  const getVasLocation = async (isLoadSplinner) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/${baseId}`;
    
    console.log(apiUrl)
    const result = await GetApi(apiUrl);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(ロケ一覧)');
      setLocationOrgList([]);
      setLocationList([]);
    } else {
      console.log(result);
      setLocationOrgList(result.data)   
      setLocationList(result.data)    
    }
  }
  // 荷主一覧の取得
  const getVasOwner = async (isLoadSplinner) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/owner/${baseId}`;
    
    console.log(apiUrl)
    const result = await GetApi(apiUrl);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(ロケ一覧)');
      setOwnerList([]);
    } else {
      console.log(result);
      setOwnerList(result.data);   
    }
  }
  // エリア一覧の取得
  const getVasArea = async (isLoadSplinner) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = `${DefaultUrl}/area/${baseId}`;
    
    console.log(apiUrl)
    const result = await GetApi(apiUrl);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(ロケ一覧)');
      setAreaList([]);
    } else {
      console.log(result);
      setAreaList(result.data);   
    }
  }
  // 描画時に一度だけ実行
  useEffect(() => {
    getVasArea(false);
    getVasLocation(true);
    getVasOwner(false);
  }, [])
  // `Update` ロケーションの入れ替え処理
  const handleChange =  async(locationNum,ownerCd,type) => {
    var index1 = locationOrgList.findIndex((location)=>location.locationNum == locationNum && location.ownerCd == ownerCd)
    var index2 = locationOrgList.findIndex((location)=>location.locationNum == locationNum+type && location.ownerCd == ownerCd)
    if(index1 == -1 || index2 == -1){
      alert.error('対象外のロケーションです。');
      return
    }
    console.log(index1,index2)
    var data1 = locationOrgList[index1]
    var data2 = locationOrgList[index2]
    if(!!data1.patternFlag || !!data2.patternFlag){
      alert.error('対象のロケーションは現在、パターンに割り振られているので更新できません。');
      return
    }
    var param1 =
    {
      baseId: data1.baseId,
      ownerCd: data1.ownerCd,
      locationNum: data1.locationNum + type,
      shippingCd: data1.shippingCd,
      shippingName: data1.shippingName,
      shippingKana: data1.shippingKana,
      companyCode: '',
      storeCode: '',
      areaId: data1.areaId,
    }
    var param2 =
    {
      baseId: data2.baseId,
      ownerCd: data2.ownerCd,
      locationNum: data2.locationNum - type,
      shippingCd: data2.shippingCd,
      shippingName: data2.shippingName,
      shippingKana: data2.shippingKana,
      companyCode: '',
      storeCode: '',
      areaId: data2.areaId,
    }
    dispatch(SetLoadSpinner(true));
    const apiUrlUpdate = `${DefaultUrl}/update`;
    const apiUrlClear = `${DefaultUrl}/clear`;
    console.log(param1)
    console.log(param2)
    var result1 = {};
    var result2 = {};
    {
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(param1)], { type: 'application/json' }))
      result1 = await MultipartApi(param1.shippingCd!=null?apiUrlUpdate:apiUrlClear,'PUT',submitData,param1);
    }
    {
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(param2)], { type: 'application/json' }))
      result2 = await MultipartApi(param2.shippingCd!=null?apiUrlUpdate:apiUrlClear,'PUT',submitData,param2);
    }

    dispatch(SetLoadSpinner(false));
    if(result1.errorDetail || result2.errorDetail)
    {
      alert.error('ロケーションの更新に失敗しました。');
    }else{
      alert.info('ロケーションの更新に成功しました。');
      getVasLocation(true);
    }
  };
  function isDuplicated(elements) {
    const uniqueElements = elements.filter(
      (element, index, self) => self.findIndex(e => e.shippingCd == element.shippingCd && e.ownerCd == element.ownerCd) === index
    );
    return uniqueElements.length !== elements.length;
  }  

  //const { loginUserInfo } = useSelector(state => state);
   // `Update` 一括更新処理
   const handleUpdate=  async() => {
    try {
      var paramList = [] 
      var checkParamList = []
      locationOrgList.map(
        (location)=>{
          var param =
          {
            baseId: location.baseId,
            ownerCd: location.ownerCd,
            locationNum: location.locationNum,
            shippingCd: location.shippingCd==null?'':location.shippingCd,
            shippingName: location.shippingName==null?'':location.shippingName,
            shippingKana: location.shippingKana==null?'':location.shippingKana,
            companyCode: '',
            storeCode: '',
            areaId: location.areaId,
          }
          let elementCd = document.getElementsByName(`shippingCd_${location.ownerCd}_${location.locationNum}`);
          let elementName = document.getElementsByName(`shippingName_${location.ownerCd}_${location.locationNum}`);
          let elementKana = document.getElementsByName(`shippingKana_${location.ownerCd}_${location.locationNum}`);
          let elementArea = document.getElementsByName(`areaId_${location.ownerCd}_${location.locationNum}`);
          if(elementCd[0].disabled) {
            checkParamList.push(
              {
                ownerCd: param.ownerCd,
                shippingCd: param.shippingCd
              }
            )
            return
          }
          let element = document.getElementsByName(`clear_${location.ownerCd}_${location.locationNum}`);
          if(element[0].checked) {
            paramList.push(
              {
                baseId: location.baseId,
                ownerCd: location.ownerCd,
                locationNum: location.locationNum,
                shippingCd: '',
                shippingName: '',
                shippingKana: '',
                companyCode: '',
                storeCode: '',
                areaId: -1,
              }
            )
            return
          }
  
          if(
            elementCd[0].value != param.shippingCd || 
            elementName[0].value != param.shippingName || 
            elementKana[0].value != param.shippingKana || 
            Number(elementArea[0].value) != Number(param.areaId) 
          ){
            paramList.push(
              {
                baseId: location.baseId,
                ownerCd: location.ownerCd,
                locationNum: location.locationNum,
                shippingCd: elementCd[0].value ,
                shippingName:   elementName[0].value,
                shippingKana: elementKana[0].value,
                companyCode: '',
                storeCode: '',
                areaId: elementArea[0].value,
              }
            )
            if(elementCd[0].value!=''){
              checkParamList.push(
                {
                  ownerCd: location.ownerCd,
                  shippingCd: elementCd[0].value ,
                }
              )
            }
          }else{
            if(param.shippingCd!=''){
              checkParamList.push(
                {
                  ownerCd: param.ownerCd,
                  shippingCd: param.shippingCd
                }
              )
            }
          }
        }
      )
  
      console.log(paramList)
  
      if(isDuplicated(checkParamList)){
        alert.error('更新する出荷先コードが重複しています。');
        return
      }
      //更新するデータがありません
      if(!paramList.length){
        alert.error('更新するパターンロケーションがありません。');
        return
      }    
      dispatch(SetLoadSpinner(true));
      
      const apiUrlUpdate = `${DefaultUrl}/update/list`;
      const submitData = new window.FormData()
      submitData.append('formData', new window.Blob([JSON.stringify(paramList)], { type: 'application/json' }))
      const result = await MultipartApi(apiUrlUpdate,'PUT',submitData,paramList);

      dispatch(SetLoadSpinner(false));
      if(result.errorDetail)
      {
        alert.error('ロケーションの更新に失敗しました。');
      }else{
        alert.info('ロケーションの更新に成功しました。');
        setIsEdit(false)
        getVasLocation(true);
      }
    } catch (error) {
    }finally{
    }
  };
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        viewSubSearch={viewSubSearch}
        onWorkplaceChange={(id)=>onWorkplaceId(id)}
        onClickOption={(view)=>setViewSubSearch(view)}
        workplaceId={workplaceId}
        baseId={baseId}
        ownerRef={ownerRef}
        locationNumRef={locationNumRef}
        shippingRef={shippingRef}
        companyRef={companyRef}
        storeRef={storeRef}
        ownerList={ownerList}
        onFilter={(newFilters)=>{setLocationList(onFilter(locationOrgList,newFilters))}}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <LocationTableBoard 
              locationList={locationList} 
              areaList={areaList} 
              onReSerach={getVasLocation} 
              handleChange={handleChange} 
              handleUpdate={handleUpdate}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default VASLocationMaster
