import React,{useState, useEffect,useCallback} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from './Common/Atoms/NormalButton'
import { useSelector, useDispatch  } from 'react-redux'
import { useHistory } from "react-router-dom"
import { SetChangeFlag,SetTimeChangeType } from './Common/Slice/SettingSlice'
import { GetApi,PostApi } from './Common/ApiAxios'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrap = styled.div`
  font-size: 24px;
  display: flex;
  padding: 1vw;
  font-weight: bold;
`
const RadioWrap = styled.div`
  display: flex;
  font-size: 18px;
  justify-content: center;
  padding: 10px;
`
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const SettingMenu = (props) => {
  const {
    //grants,
    open, setOpen
  } = props

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked,setChecked]=useState(true)
  const [timeChecked,setTimeChecked]=useState(true)
  const { SettingInfo } = useSelector(state => state)
  const { staffId } = useSelector(state => state.loginUserInfo)
  const [dialogHeight,setDaialogHeight] = useState('200px')
  useEffect(() => {
    // 必要なデータが更新された場合のみ実行
    // eslint-disable-next-line
  }, [])

  /* 2024/02/14 ADD 画面切り替えフラグをDBから取得更新できるように変更　start */
  useEffect(() => {
    // 必要なデータが更新された場合のみ実行
    // eslint-disable-next-line
    if(staffId && SettingInfo.workplaceId)
      apiGetFlag()
  }, [staffId,SettingInfo.workplaceId])
  //画面切り替えフラグの取得
  const apiGetFlag = async () => {
    const apiUrl = '/api/logisticManagement/getWindowChangeFlag';
    const data = {
      workplaceId: SettingInfo.workplaceId,
      staffId: staffId
    }
    console.log(data)
    const result = await GetApi(apiUrl, data);
    if (result.errorDetail) { 
    } else {
      console.log(result.data);
      //画面切り替え
      setChecked(result.data.windowsChangeFlag);
      dispatch(SetChangeFlag(result.data.windowsChangeFlag));
      //日付切り替え
      setTimeChecked(result.data.timeChangeFlag);
      dispatch(SetTimeChangeType(result.data.timeChangeFlag));
      //日付切り替え時刻
      dispatch(SetTimeChangeType(result.data.changeTime));
    }
  }
  // 画面切り替えフラグの更新
  const apiPostFlag = async () => {  
      const apiUrl = '/api/logisticManagement/updateWindowChangeFlag';
      const param = {
        workplaceId: SettingInfo.workplaceId,
        staffId: staffId,
        flag: checked?1:0,
        timeFlag: timeChecked?1:0,
      }
      //console.log(param);
      const result = await PostApi(apiUrl, param);
      if (result.errorDetail) {
        //alert.error('年間のCO2排出量目標値の更新失敗');
      }else{
        apiGetFlag();
      }
  }

  // 画面切り替えフラグの更新
  const apiPostData = async () => {  
    const apiUrl = '/api/pc-total-shipping-schedule/regist-shipping-list-seling-result';
    const apiUrlS = '/api/pc-total-shipping-schedule/update-shipping-list-seling-status';
    var param =
    {
      status:3,
      area_id:2,
      delivery_division:1,
      delivery_dt:'2024-08-16',
      item_no_list:['105862'],
      quantity_value:99,
      suntenor_value:0,
      seling_price_list:[''],
      user_id:30,
      cargo_id:0
    }
    var param1 =
    {
      status:1,
      area_id:2,
      delivery_division:1,
      delivery_dt:'2024-08-16',
      item_no_list:['105862'],
      seling_price_list:[''],
      user_id:30
    }
    var param2 =
    {
      status:3,
      area_id:2,
      delivery_division:1,
      delivery_dt:'2024-08-16',
      item_no_list:['105862'],
      seling_price_list:[''],
      user_id:30
    }

    const result1 = await PostApi(apiUrlS, param1,true);
    if(result1.errorDetail)
    {
      alert.error('ステータスの更新に失敗：１');
    }else{
      //console.log(param);
      const result = await PostApi(apiUrl, param,true);
      if (result.errorDetail) {
        alert.error('実績の更新に失敗');
      }else{
        const result2 = await PostApi(apiUrlS, param2,true);
        if(result2.errorDetail){
          alert.error('ステータスの更新に失敗：3');
        }
      }
    }

}

  /* 2024/02/14 ADD 画面切り替えフラグをDBから取得更新できるように変更　end */
  
  //ラジオボタン変更処理
  const handleCheckChange = () => {
    setChecked(!checked)
    dispatch(SetChangeFlag(!checked));
  };
  //ラジオボタン変更処理
  const handleTimeCheckChange = () => {
    setTimeChecked(!timeChecked)
    dispatch(SetTimeChangeType(!timeChecked));
  };
  //ダイアログ開いた時の処理
  function afterOpenModal () {
    if(history && history.location && (history.location.pathname == '/logistic/sorterPerformance'  || history.location.pathname == '/logistic')) setDaialogHeight('350px')
    else setDaialogHeight('250px')
    if(SettingInfo)setChecked(SettingInfo.changeFlag)
  }
  const onSubmit=()=>{apiPostData()}
  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1000
    }
  }
  const onOpenStaff=()=>{
    onCloseFunc()
    history.push('/master/staff');
  }
  const onCloseFunc=()=>{
    apiPostFlag();
    setOpen(false)
  }
  return (
    <Modal
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={onCloseFunc}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
    <div style={{ width:'800px', height:'300px', marginLeft:'1rem' }}>
      <ContentWrap>
      <TitleWrap>
          <label>設定</label>
      </TitleWrap>
      </ContentWrap>
      <TableWrap>
        <label style={{marginTop:'12px',marginLeft:'0px',width:'10rem' ,fontWeight: 'bold',fontSize:'18px'}}>
          {/* {SettingInfo.workplaceId!='000011'?'種まき実績表示':'ソーター実績表示'} */}
          {'画面切替設定'}
        </label>
        <RadioWrap>
            <input type="radio" value={0} name="rest" id={'rest0'} checked={checked} onChange={()=>handleCheckChange()}/>
            <label for={'rest0'} name="rest">画面切替</label>
            <input type="radio" value={1} name="rest" id={'rest1'} checked={!checked} onChange={()=>handleCheckChange()}/>
            <label for={'rest1'} name="rest">画面固定</label>
        </RadioWrap>
      </TableWrap>
      <TableWrap>
        <label style={{marginTop:'12px',marginLeft:'0px',width:'10rem' ,fontWeight: 'bold',fontSize:'18px'}}>
          {/* {SettingInfo.workplaceId!='000011'?'種まき実績表示':'ソーター実績表示'} */}
          {'日付切替設定'}
        </label>
        <RadioWrap>
            <input type="radio" value={0} name="time" id={'time0'} checked={timeChecked} onChange={()=>handleTimeCheckChange()}/>
            <label for={'time0'} name="time">日付切替</label>
            <input type="radio" value={1} name="time" id={'time1'} checked={!timeChecked} onChange={()=>handleTimeCheckChange()}/>
            <label for={'time1'} name="time">日付固定</label>
        </RadioWrap>
      </TableWrap>
      <ButtonsWrap>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
        <Button name='更新' padding='0px' onClick={()=>apiPostData()} />
      </ButtonsWrap>
    </div>
  </Modal>
  )
}

SettingMenu.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
}

export default SettingMenu
