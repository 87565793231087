import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogStyle = styled.div`
  width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 18px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 16px;
`;

const Label = styled.label`
  width: 120px;
  text-align: left;
`;

const InputField = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectField = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 30px;
`;

const Button = styled.button`
  width: 90px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #649cdf;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

  &.clear-btn {
    background-color: #979797;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
  &.clear-btn:hover {
    background-color: #858585;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
`;
const NewButton = styled.button`
  width: 110px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: #649cdf;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

  &.clear-btn {
    background-color: #979797;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
  &.clear-btn:hover {
    background-color: #858585;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }
`;
const PatternDialog = ({ 
  onClose, 
  onUpdate, 
  ownerList,
  patternList
}) => {

  const [filters, setFilters] = useState({
    ownerCd: '1',
    patternCd: 'BEIHAN1',
    patternName: patternList.filter((item)=>item.ownerCd==1 && item.patternCd=='BEIHAN1')[0].patternName,
    patternKana: patternList.filter((item)=>item.ownerCd==1 && item.patternCd=='BEIHAN1')[0].patternKana,
    cooperationFlag: patternList.filter((item)=>item.ownerCd==1 && item.patternCd=='BEIHAN1')[0].cooperationFlag,
  });
  const [newPattern, setNewPattern] = useState(false);
  const handleUpdate = () => {
    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== '')
    );
    onClose();
    onUpdate(validFilters);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: value }));
  };  

  const handleOwnerInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: value }));
    var patternCd = ''
    var patternName = ''
    var patternKana = ''
    var cooperationFlag = 0
    var pattern = patternList.filter((item)=>item.ownerCd==value)
    if(pattern.length){
      patternCd = pattern[0].patternCd
      patternKana = pattern[0].patternKana
      patternName = pattern[0].patternName
      cooperationFlag = pattern[0].cooperationFlag
    } 
    setFilters((prev) => ({ ...prev, ['patternCd']: patternCd }));
    setFilters((prev) => ({ ...prev, ['patternName']: patternName }));
    setFilters((prev) => ({ ...prev, ['patternKana']: patternKana }));
    setFilters((prev) => ({ ...prev, ['cooperationFlag']: cooperationFlag }));
  };  
  const handlePatternInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: value }));

    var patternName = ''
    var patternKana = ''
    var pattern = patternList.filter((item)=>item.ownerCd == filters.ownerCd && item.patternCd == value)
    var cooperationFlag = 0
    if(pattern.length){
      patternKana = pattern[0].patternKana
      patternName = pattern[0].patternName
      cooperationFlag = pattern[0].cooperationFlag
    } 
    setFilters((prev) => ({ ...prev, ['patternName']: patternName }));
    setFilters((prev) => ({ ...prev, ['patternKana']: patternKana }));
    setFilters((prev) => ({ ...prev, ['cooperationFlag']: cooperationFlag }));
    console.log( pattern[0].cooperationFlag)
  }; 
  const handleChechBoxInputChange = (e) => {
    const { id } = e.target;
    var value = !filters[id]?1:0
    setFilters((prev) => ({ ...prev, [id]: value }));
  }; 
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };


  return (
    <DialogOverlay onClick={handleOverlayClick}>
      <DialogStyle>
        <Title><h2>パターン登録</h2></Title>
        <div>
          <FormRow>
            <Label htmlFor="ownerCd">荷主</Label>
            <SelectField
              id="ownerCd"
              value={filters.ownerCd}
              onChange={handleOwnerInputChange}
            >
              {ownerList.map(item => (
                <option key={'owner_'+item.ownerCd} value={item.ownerCd}>{item.ownerName}</option>
              ))}
            </SelectField>
          </FormRow>
          <FormRow>
            <Label htmlFor="patternCd">パターンコード</Label>
            {!newPattern &&
            <>
            <SelectField
              id="patternCd"
              value={filters.patternCd}
              onChange={handlePatternInputChange}
            >
              {patternList.filter((item)=>item.ownerCd==filters.ownerCd).map((item,index) => (
                  <option key={'owner_'+item.patternCd} value={item.patternCd} selected={index==1}>{item.patternCd}</option>
              ))}
            </SelectField>
            <NewButton onClick={()=>setNewPattern(true)}>
              新規登録
            </NewButton>
            </>
            }
            {newPattern &&
            <>
            <InputField
              type="text"
              id="patternCd"
              onChange={handleInputChange}
            />
            <NewButton onClick={()=>setNewPattern(false)}>
              キャンセル
            </NewButton>
            </>
            }
          </FormRow>
          <FormRow>
            <Label htmlFor="patternName">パターン名</Label>
            <InputField
              type="text"
              id="patternName"
              value={filters.patternName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="patternKana">パターン名カナ</Label>
            <InputField
              type="text"
              id="patternKana"
              value={filters.patternKana}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow  style={{justifyContent: 'start'}}>
            <Label htmlFor="cooperationFlag">コアボート連携</Label>
            <input
              type="checkbox"
              id="cooperationFlag" 
              name="cooperationFlag" 
              checked={!filters.cooperationFlag}
              onClick={handleChechBoxInputChange}
            />
          </FormRow>
        </div>
        <ButtonContainer>
          <Button className="clear-btn" onClick={onClose}>閉じる</Button>
          <Button onClick={handleUpdate}>登録</Button>
        </ButtonContainer>
      </DialogStyle>
    </DialogOverlay>
  );
};

PatternDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  initialFilters: PropTypes.object.isRequired,
  ownerList: PropTypes.array,
};

export default PatternDialog;
