import React from 'react'
import styled from 'styled-components'

const ContentWrap = styled.form`
  display: block; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  text-align: center;
`

const Detail = (props) => {
  return (
    <ContentWrap>
      <iframe 
        width="1250px" 
        height="840px" 
        src="https://lookerstudio.google.com/embed/reporting/25edceae-61bc-4e9c-87b7-cff498b01c9a/page/MRCwD" 
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
        allowFullScreen 
      >
      </iframe>
    </ContentWrap>
  )
}

export default Detail
