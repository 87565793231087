import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import editIcon from '../../../../image/edit-icon2.png'; 

const MediaWrap = styled.div`
  width:1837px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 3rem;
  cursor: pointer;

  img {
    width: 20px; /* アイコンの幅 */
    height: 20px; /* アイコンの高さ */
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1); /* ホバー時に拡大 */
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 2rem;
  gap: 20px;


  button {
    width: 96px;
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    background-color: #649cdf;
    color: #fff;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);

  &:hover {
    background-color: #4682c4;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  }

    &.delete {
        background-color: #979797;
        box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
      &:hover {
        background-color: #858585;
        box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
      }
    }
  }
`;

const EditableInput = styled.input`
  flex: 1;
  width: 96px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: right;
`;

const MediaDataDaily = (props) => {
  const {
    data,
    updateSuntenorQuantity,
  } = props

  const [isEditing, setIsEditing] = useState(false);
  const [quantityInSuntenor, setQuantityInSuntenor] = useState(Number(data.quantityInSuntenor) || 0);


  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdate = () => {
    const formData = {
        id: Number(data.id),
        itemNo: data.itemNo,
        quantityInSuntenor: Number(quantityInSuntenor), // quantityInSuntenor を数値型に変換
    };
    console.log('id:', formData.id, 'Type:', typeof formData.id);
    console.log('quantityInSuntenor:', formData.quantityInSuntenor, 'Type:', typeof formData.quantityInSuntenor);

    updateSuntenorQuantity(formData.id, formData);
    setIsEditing(false);
  };


  const handleCancel = () => {
    setQuantityInSuntenor(data.quantityInSuntenor);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value; // 入力値を取得
    if (value === "") {
      setQuantityInSuntenor("");
    } else {
      setQuantityInSuntenor(value);
    }
  };  

  if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'180px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.itemNo}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'450px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.itemName}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'350px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.itemKana}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{ width: '160px', textAlign: 'center' }}>
          <div>
            {Number(data.pcrsDivision) === 0 ? 'PC' : Number(data.pcrsDivision) === 1 ? 'RS' : ''}
          </div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'220px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.janCode}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{ width: '160px', textAlign: 'right' }}>
        <div style={{marginRight:'2rem'}}>
          {isEditing ? (
            <EditableInput
              type="number"
              value={quantityInSuntenor}
              onChange={handleInputChange}
            />
          ) : (
            quantityInSuntenor
          )}
        </div>
        </TextWrap>
      </MediaItem>
      {isEditing ? (
        <ButtonContainer>
          <button onClick={handleUpdate}>更新</button>
          <button className="delete" onClick={handleCancel}>キャンセル</button>
        </ButtonContainer>
      ) : (
        <IconContainer onClick={handleEdit}>
          <img src={editIcon} alt="編集" />
        </IconContainer>
      )}
    </MediaWrap>
  )
}

MediaDataDaily.propTypes = {
  data: PropTypes.object.isRequired,
  updateSuntenorQuantity: PropTypes.func.isRequired,
}

export default MediaDataDaily
