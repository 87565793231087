import React,{useState} from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

import MediaHeaderDaily from './Atoms/MediaHeaderLocation'
//import MediaDataLocation from './Atoms/MediaDataLocation'
import MediaDataLocationEdit from './Atoms/MediaDataLocationEdit'

import DownloadIcon from '../../../image/down-load-csv-icon.png'
import Tooltip from '@material-ui/core/Tooltip'
import {onExportCsvFile} from '../../Common/Utilties/ExportUtility'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'

import EditIcon from '../../../image/edit-icon.png'
import UpdateIcon from '../../../image/update-icon.png'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
  height: 520px;
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
  padding:0.5rem;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
const FunctionIcon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
const LocationTableBoard = (props) => {
  const {
    locationList,
    onReSerach,
    handleChange,
    areaList,
    handleUpdate,
    isEdit,
    setIsEdit
  } = props
  const dispatch = useDispatch();
  const alert = useAlert();
  const onExport=()=>{
    let exportResult = makeCSV()
    let fileName = LogisticUtility.getStringFromDate(new Date(), 'YYYYMMDD') + "AFS尾西センター_ロケ一覧";
    onExportCsvFile(exportResult,fileName);
  }
  function makeCSV() 
  {
    let Header = ''
    let header =[
      '荷主コード',
      '荷主名',
      'ロケーション番号',
      '出荷先コード',
      '出荷先名',
      '出荷先カナ'
      ]
    header.map((head)=>{
      //console.log(head)
      Header+=head
      Header+=','
    })
    let result = Header + '\r\n'
    var tmpList=JSON.parse(JSON.stringify(locationList))
    tmpList.map(function(value) {
      var lineResult =
        value.ownerCd + ',' +
        value.ownerName + ',' +
        value.locationNum + ',' +
        value.shippingCd + ',' +
        value.shippingName + ',' +
        value.shippingKana + ',' 
  
      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })

    return result
  }

  return (
    <ContentWrap>
      <BodyContent>
        <div style={{width:'1817px', marginLeft:'20px', marginRight:'20px', textAlign:'left'}}>
        {/* <div style={{textAlign:'right',flexDirection:'row',marginRight:'17px'}}>
          <Tooltip title={'csvダウンロード'}>
            <FunctionIcon  alt='ダウンロード' src={DownloadIcon}  style={{marginRight:'1rem'}} onClick={onExport}/>
          </Tooltip>
          {locationList.length.toLocaleString()}件
        </div> */}
        <div style={{textAlign:'left',flexDirection:'row',marginRight:'17px',marginLeft:'10px'}}>
          <Tooltip title={'編集'}>
            <FunctionIcon  alt='編集' src={EditIcon}  style={{marginRight:'1rem'}} onClick={()=>setIsEdit(!isEdit)}/>
          </Tooltip>
          {isEdit &&
          <Tooltip title={'更新'}>
            <FunctionIcon  alt='更新' src={UpdateIcon}  style={{marginRight:'1rem'}} onClick={()=>
              {
                handleUpdate()
              }
              }/>
          </Tooltip>
          }
          <div style={{textAlign:'right',flexDirection:'row',float:'right'}}>
            <Tooltip title={'csvダウンロード'}>
              <FunctionIcon  alt='ダウンロード' src={DownloadIcon}  style={{marginRight:'1rem'}} onClick={onExport}/>
            </Tooltip>
            {locationList?.length.toLocaleString()}件
          </div>
        </div>
        <MediaHeaderDaily />
        <ListUl width={locationList.length >= 15 ? '1817px' : '1810px'} height={'643px'}>
        {locationList.map((item,index) => (
          <ListLi key={item.ownerCd + '_' + item.locationNum +'_' + index} style={{cursor: 'default'}}>
          <MediaDataLocationEdit data={item} onReSerach={onReSerach} handleChange={handleChange} areaList={areaList} isEdit={isEdit}setIsEdit={setIsEdit}/>
          </ListLi>
        ))}
        </ListUl>
        </div>
      </BodyContent>
    </ContentWrap>
  )
}

LocationTableBoard.propTypes = {
}

export default LocationTableBoard
