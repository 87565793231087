import React, { useEffect } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1798px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const MediaTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const MediaHeaderLocation = (props) => {
  const {
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <MediaTitle style={{width:'150px'}}>荷主名</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px'}}>ロケーション番号</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px'}}>出荷先コード</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'300px'}}>出荷先名</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'300px'}}>出荷先カナ</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px'}}>会社コード</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px'}}>店舗コード</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px'}}>エリア名</MediaTitle>
      </MediaItem>
    </MediaWrap>
  )
}

MediaHeaderLocation.propTypes = {
}

export default MediaHeaderLocation
